import * as React from 'react';
import { useLocation } from '@reach/router';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  LinkedinShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

const ShareBlock = ({ data, title, description, blockStyle }) => {
  
  const { t } = useTranslation();

  const location = useLocation();
  const url = location.href;
  
  return (
    <>
      {blockStyle === "1" &&
        <>
          <LinkedinShareButton
            url={url}
            data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.linkedIn.tooltip')}
          >
            <span className="btn btn-soft-secondary btn-sm btn-icon rounded-circle">
              <i className="bi-linkedin"></i>
            </span>                    
          </LinkedinShareButton>    
          <FacebookShareButton
            url={url}
            quote={description}
            data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.facebook.tooltip')}
          >
            <span className="btn btn-soft-secondary btn-sm btn-icon rounded-circle">
              <i className="bi-facebook"></i>
            </span>                    
          </FacebookShareButton>                
          <FacebookMessengerShareButton
            url={url}
            appId={process.env.GATSBY_META_API_ID}
            data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.messenger.tooltip')}
          >
            <span className="btn btn-soft-secondary btn-sm btn-icon rounded-circle">
              <i className="bi-messenger"></i>
            </span>                    
          </FacebookMessengerShareButton>      
          <TwitterShareButton
            url={url}
            title={description}
            data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.twitter.tooltip')}
          >
            <span className="btn btn-soft-secondary btn-sm btn-icon rounded-circle">
              <i className="bi-twitter"></i>
            </span>                    
          </TwitterShareButton>  
          <WhatsappShareButton
            url={url}
            title={title}
            separator=" : "
            data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.whatsApp.tooltip')}
          >
            <span className="btn btn-soft-secondary btn-sm btn-icon rounded-circle">
              <i className="bi-whatsapp"></i>
            </span>                    
          </WhatsappShareButton>
        </>  
      }
      {blockStyle === "2" &&
        <>
          <LinkedinShareButton
            url={url}
            data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.linkedIn.tooltip')}
          >
            <span className="btn btn-ghost-secondary btn-sm btn-icon rounded-circle me-2">
              <i className="bi-linkedin"></i>
            </span>                    
          </LinkedinShareButton>    
          <FacebookShareButton
            url={url}
            quote={description}
            data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.facebook.tooltip')}
          >
            <span className="btn btn-ghost-secondary btn-sm btn-icon rounded-circle me-2">
              <i className="bi-facebook"></i>
            </span>                    
          </FacebookShareButton>                
          <FacebookMessengerShareButton
            url={url}
            appId={process.env.GATSBY_META_API_ID}
            data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.messenger.tooltip')}
          >
            <span className="btn btn-ghost-secondary btn-sm btn-icon rounded-circle me-2">
              <i className="bi-messenger"></i>
            </span>                    
          </FacebookMessengerShareButton>      
          <TwitterShareButton
            url={url}
            title={description}
            data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.twitter.tooltip')}
          >
            <span className="btn btn-ghost-secondary btn-sm btn-icon rounded-circle me-2">
              <i className="bi-twitter"></i>
            </span>                    
          </TwitterShareButton>  
          <WhatsappShareButton
            url={url}
            title={title}
            separator=" : "
            data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.whatsApp.tooltip')}
          >
            <span className="btn btn-ghost-secondary btn-sm btn-icon rounded-circle me-2">
              <i className="bi-whatsapp"></i>
            </span>                    
          </WhatsappShareButton>
        </>  
      }
      {blockStyle === "3" &&
        <>
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <LinkedinShareButton
                url={url}
                data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.linkedIn.tooltip')}
              >
                <span className="btn btn-soft-secondary btn-xs btn-icon">
                  <i className="bi-linkedin"></i>
                </span>      
              </LinkedinShareButton>    
            </li> 
            <li className="list-inline-item">             
              <FacebookShareButton
                url={url}
                quote={description}
                data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.facebook.tooltip')}
              >
                <span className="btn btn-soft-secondary btn-xs btn-icon">
                  <i className="bi-facebook"></i>
                </span>  
              </FacebookShareButton>  
            </li>
            <li className="list-inline-item">              
              <FacebookMessengerShareButton
                url={url}
                appId={process.env.GATSBY_META_API_ID}
                data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.messenger.tooltip')}
              >
                <span className="btn btn-soft-secondary btn-xs btn-icon">
                  <i className="bi-messenger"></i>
                </span>  
              </FacebookMessengerShareButton> 
            </li> 
            <li className="list-inline-item">    
              <TwitterShareButton
                url={url}
                title={description}
                data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.twitter.tooltip')}
              >
                <span className="btn btn-soft-secondary btn-xs btn-icon">
                  <i className="bi-twitter"></i>
                </span>  
              </TwitterShareButton>  
            </li>
             <li className="list-inline-item">
              <WhatsappShareButton
                url={url}
                title={title}
                separator=" : "
                data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.whatsApp.tooltip')}
              >
                <span className="btn btn-soft-secondary btn-xs btn-icon">
                  <i className="bi-whatsapp"></i>
                </span> 
              </WhatsappShareButton>
            </li>
          </ul>
        </>
      }
      {blockStyle === "4" &&
        <>
          <div className="dropdown">
            <button className="btn btn-outline-primary btn-sm btn-icon" id="employeeProfileShareDropdown" data-bs-toggle="dropdown" aria-expanded="false" data-bs-dropdown-animation="">
              <i className="bi-share-fill"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeProfileShareDropdown">
              <div className="dropdown-item">
                <LinkedinShareButton
                  url={url}
                  data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.linkedIn.tooltip')}
                >
                  <i className="bi-linkedin dropdown-item-icon"></i> {t('shareOn.linkedIn.title')}
                </LinkedinShareButton>    
              </div> 
              <div className="dropdown-item">             
                <FacebookShareButton
                  url={url}
                  quote={description}
                  data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.facebook.tooltip')}
                >
                  <i className="bi-facebook dropdown-item-icon"></i> {t('shareOn.facebook.title')}
                </FacebookShareButton>  
              </div>
              <div className="dropdown-item">              
                <FacebookMessengerShareButton
                  url={url}
                  appId={process.env.GATSBY_META_API_ID}
                  data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.messenger.tooltip')}
                >
                  <i className="bi-messenger dropdown-item-icon"></i> {t('shareOn.messenger.title')}
                </FacebookMessengerShareButton> 
              </div> 
              <div className="dropdown-item">    
                <TwitterShareButton
                  url={url}
                  title={description}
                  data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.twitter.tooltip')}
                >
                  <i className="bi-twitter dropdown-item-icon"></i> {t('shareOn.twitter.title')}
                </TwitterShareButton>  
              </div>
              <div className="dropdown-item">
                <WhatsappShareButton
                  url={url}
                  title={title}
                  separator=" : "
                  data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('shareOn.whatsApp.tooltip')}
                >
                  <i className="bi-whatsapp dropdown-item-icon"></i> {t('shareOn.whatsApp.title')}
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default ShareBlock;
