import * as React from 'react';

// Components
import EducationCard from '../../components/card/Education';

const EducationsSection = ({ data, educations }) => {
  return (
    <>
      <ul className="step step-icon-sm">
        {educations.nodes &&
          educations.nodes.map(education => (
            <EducationCard key={education.id} data={education} cardStyle="1"/>
          ))
        }
      </ul>
    </>
  );
};

export default EducationsSection