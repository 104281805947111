import * as React from 'react';
import { useTranslation} from 'gatsby-plugin-react-i18next';

const LanguageCard = ({ data, cardStyle, cardClassName }) => {
  
  const {t} = useTranslation();

  return (
    <>
    {cardStyle === "1" &&
      <li key={data.id}>
        <div className="d-flex justify-content-between w-50">
          <div className="text-dark">
            {t('language.' + data.frontmatter.language)} 
          </div>          
          <div className="text-body small ms-1 float-right">
            {data.frontmatter.proficiency === '1' &&
              <div>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-muted me-1"></i>
                <i className="bi bi-record-circle-fill text-muted me-1"></i>
                <i className="bi bi-record-circle-fill text-muted me-1"></i>
                <i className="bi bi-record-circle-fill text-muted me-1"></i>
              </div>
            }
            {data.frontmatter.proficiency === '2' &&
              <div>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-muted me-1"></i>
                <i className="bi bi-record-circle-fill text-muted me-1"></i>
                <i className="bi bi-record-circle-fill text-muted me-1"></i>
              </div>
            }
            {data.frontmatter.proficiency === '3' &&
              <div>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-muted me-1"></i>
                <i className="bi bi-record-circle-fill text-muted me-1"></i>
              </div>
            }
            {data.frontmatter.proficiency === '4' &&
              <div>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-muted me-1"></i>
              </div>
            }
            {data.frontmatter.proficiency === '5' &&
              <div>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
                <i className="bi bi-record-circle-fill text-primary me-1"></i>
              </div>
            }
          </div>          
        </div>
         <div className="text-body small">{t('language.proficiency.' + data.frontmatter.proficiency)}</div>
      </li>
    }
    </>
  )
}

export default LanguageCard