import * as React from 'react';

// Components
import VolunteeringCard from '../../components/card/Volunteering';

const VolunteeringsSection = ({ data, volunteerings }) => {
  return (
    <>
      <ul className="step step-icon-sm">
        {volunteerings.nodes &&
          volunteerings.nodes.map(volunteering => (
            <VolunteeringCard key={volunteering.id} data={volunteering} cardStyle="1"/>
          ))
        }
      </ul>
    </>
  );
};

export default VolunteeringsSection