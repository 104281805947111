import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, useTranslation} from 'gatsby-plugin-react-i18next';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

const VolunteeringCard = ({ data, cardStyle, cardClassName }) => {
  
  const {t} = useTranslation();

  return (
    <>
    {cardStyle === "1" &&
      <li key={data.id} className="step-item" data-aos="fade-up">
        <div className="step-content-wrapper">
          {data.frontmatter.image.logo && 
            <>
              {data.frontmatter.website_url ?
                <a href={data.frontmatter.website_url} target="_blank" rel="noreferrer noopener">
                  <div className="step-avatar step-avatar-md">
                    <GatsbyImage
                      image={getImage(data.frontmatter.image.logo)}
                      className="step-avatar-img"
                      alt={data.frontmatter.image.alt}
                    />
                  </div>
                </a>
              :
                <div className="step-avatar step-avatar-md">
                  <GatsbyImage
                    image={getImage(data.frontmatter.image.logo)}
                    className="step-avatar-img"
                    alt={data.frontmatter.image.alt}
                  />
                </div>
              }
            </>
          }
          <div className="step-content">
            <h4 className="step-title">{data.frontmatter.title}</h4>
            {data.frontmatter.website_url ?
              <span className="d-block text-dark"><a href={data.frontmatter.website_url} className="text-dark" target="_blank" rel="noreferrer noopener">{data.frontmatter.organization}</a> - {data.frontmatter.city} {data.frontmatter.country}</span>
            :
              <span className="d-block text-dark">{data.frontmatter.organization} - {data.frontmatter.city} {data.frontmatter.country}</span>
            }
            {data.frontmatter.date_end ?
              <small className="d-block mb-4">{t('dateStartEnd', { date_start: data.frontmatter.date_start, date_end: data.frontmatter.date_end })}</small>
            :
              <small className="d-block mb-4">{t('dateStartPresent', { date_start: data.frontmatter.date_start, date_end: data.frontmatter.date_end })}</small>
            }
            <div className="text-body mb-0">
              <MDXProvider
                components={{
                  a: props => <a {...props} className="text-highlight-warning" target="_blank" rel="noopener noreferrer">{props.children}</a>,
                  Link: props => <Link {...props} className="text-highlight-warning">{props.children}</Link>,
                }}
              >
                <MDXRenderer>
                {data.body}
                </MDXRenderer>
              </MDXProvider> 
            </div>
          </div>
        </div>
      </li>
    }
    </>
  )
}

export default VolunteeringCard