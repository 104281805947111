import * as React from 'react';

const InterestCard = ({ data, cardStyle, cardClassName }) => {
  
  return (
    <>
    {cardStyle === "1" &&
      <span key={data.id} className="badge bg-soft-dark text-dark py-2 px-3 my-1 me-1">{data.frontmatter.title}</span>
    }
    </>
  )
}

export default InterestCard