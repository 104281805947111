import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation} from 'gatsby-plugin-react-i18next';

const CertificationCard = ({ data, cardStyle, cardClassName }) => {
  
  const {t} = useTranslation();

  return (
    <>
    {cardStyle === "1" &&
      <li key={data.id} className="step-item" data-aos="fade-up">
        <div className="step-content-wrapper">
          {data.frontmatter.image.logo && 
            <>
              {data.frontmatter.website_url ?
                <a href={data.frontmatter.website_url} target="_blank" rel="noreferrer noopener">
                  <div className="step-avatar step-avatar-md">
                    <GatsbyImage
                      image={getImage(data.frontmatter.image.logo)}
                      className="step-avatar-img"
                      alt={data.frontmatter.image.alt}
                    />
                  </div>
                </a>
              :
                <div className="step-avatar step-avatar-md">
                  <GatsbyImage
                    image={getImage(data.frontmatter.image.logo)}
                    className="step-avatar-img"
                    alt={data.frontmatter.image.alt}
                  />
                </div>
              }
            </>
          }
          <div className="step-content">
            <h4 className="step-title">{data.frontmatter.title}</h4>
            {data.frontmatter.website_url ?
              <span className="d-block text-dark"><a href={data.frontmatter.website_url} className="text-dark" target="_blank" rel="noreferrer noopener">{data.frontmatter.organization}</a></span>
            :
              <span className="d-block text-dark">{data.frontmatter.organization}</span>
            }
            {data.frontmatter.date_expiry ?
              <small className="d-block">{t('dateIssueExpiry', { date_issue: data.frontmatter.date_issue, date_expiry: data.frontmatter.date_expiry })}</small>
            :
              <small className="d-block">{t('dateIssue', { date_issue: data.frontmatter.date_issue, date_expiry: data.frontmatter.date_expiry })}</small>
            }
            <small className="d-block mb-2">{t('credentialID', { credential_id: data.frontmatter.credential_id })}</small>
            <a href={data.frontmatter.credential_url} className="btn btn-white rounded-pill" target="_blank" rel="noreferrer noopener">
              {t('showCredential')}
            </a>
          </div>
        </div>
      </li>
    }
    </>
  )
}

export default CertificationCard