import * as React from 'react';

// Components
import CertificationCard from '../../components/card/Certification';

const CertificationsSection = ({ data, certifications }) => {
  return (
    <>
      <ul className="step step-icon-sm">
        {certifications.nodes &&
          certifications.nodes.map(certification => (
            <CertificationCard key={certification.id} data={certification} cardStyle="1"/>
          ))
        }
      </ul>
    </>
  );
};

export default CertificationsSection