import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import SkillCard from '../../components/card/Skill';

const SkillsSection = ({ data, skills_technical, skills_professional, skills_personal }) => {

  const {t} = useTranslation();

  return (
    <>
      <div key="technical">
        <h5 className="border-bottom pb-2 mb-3">{t('skills.technical')}</h5>
        {skills_technical.nodes &&
          skills_technical.nodes.map(skill => (
            <SkillCard key={skill.id} data={skill} cardStyle="1"/>
          ))
        }
      </div>
      <div key="professional">
        <h5 className="border-bottom pb-2 mt-5 mb-3">{t('skills.professional')}</h5>
        {skills_professional.nodes &&
          skills_professional.nodes.map(skill => (
            <SkillCard key={skill.id} data={skill} cardStyle="2"/>
          ))
        }
      </div>
      <div key="personal">
        <h5 className="border-bottom pb-2 mt-5 mb-3">{t('skills.personal')}</h5>
        {skills_personal.nodes &&
          skills_personal.nodes.map(skill => (
            <SkillCard key={skill.id} data={skill} cardStyle="2"/>
          ))
        }
      </div>
    </>
  );
};

export default SkillsSection