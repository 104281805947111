import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useTranslation} from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const TestimonialsSection = ({ data, testimonials, sectionStyle, service }) => {
  
  const {t} = useTranslation();

  return (
    <>
      {sectionStyle === "1" &&
        <div className="row">
          <Swiper
            className="container"
            modules={[Pagination, A11y]}
            scrollbar={{ draggable: true }}
            breakpoints={{
              "380": {
                "slidesPerView": 1,
                "spaceBetween": 15,
              },
              "580": {
                "slidesPerView": 1,
                "spaceBetween": 15,
              },
              "768": {
                "slidesPerView": 2,
                "spaceBetween": 15,
              },
              "1024": {
                "slidesPerView": 3,
                "spaceBetween": 30,
              }
            }}
            pagination={{ 
              dynamicBullets: true,
              clickable: true 
            }}
            data-aos="fade-up" 
            data-aos-delay="100"
          >
            {testimonials.nodes.map((testimonial, index) => (
              <SwiperSlide key={index} className="my-2">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex gap-1 mb-2">
                      {testimonial.frontmatter.rating === '1' &&
                        <div>
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                        </div>
                      }
                      {testimonial.frontmatter.rating === '2' &&
                        <div>
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                        </div>
                      }
                      {testimonial.frontmatter.rating === '3' &&
                        <div>
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                        </div>
                      }
                      {testimonial.frontmatter.rating === '4' &&
                        <div>
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                        </div>
                      }
                      {testimonial.frontmatter.rating === '5' &&
                        <div>
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                        </div>
                      }
                    </div>
                    <div className="mb-auto">
                      <div className="card-text">
                        <MDXRenderer>
                          {testimonial.body}
                        </MDXRenderer>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer pt-0">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        {testimonial.frontmatter.from === 'google' &&
                          <StaticImage className="avatar avatar-xs avatar-circle" quality="50" src="../../images/brands/google-icon.svg" alt={t('testimonials.google')} />
                        }
                        {testimonial.frontmatter.from === 'facebook' &&
                          <StaticImage className="avatar avatar-xs avatar-circle" quality="50" src="../../images/brands/facebook-icon.svg" alt={t('testimonials.facebook')} />
                        }
                        {testimonial.frontmatter.from === 'trustpilot' &&
                          <StaticImage className="avatar avatar-xs avatar-circle" quality="50" src="../../images/brands/trustpilot-icon.svg" alt={t('testimonials.trustpilot')} />
                        }
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h5 className="card-title mb-0">{testimonial.frontmatter.name}</h5>
                        <p className="card-text small"><a href={testimonial.frontmatter.link} className="text-muted" target="_blank" rel="noreferrer noopener">{t(`testimonials.${testimonial.frontmatter.from}`)}</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))
          }
          </Swiper>
        </div>
      }
      {sectionStyle === "2" &&
        <div className="row">
          <Swiper
            className="container"
            modules={[A11y]}
            scrollbar={{ draggable: true }}
            breakpoints={{
              "380": {
                "slidesPerView": 1,
                "spaceBetween": 15,
              },
              "580": {
                "slidesPerView": 1,
                "spaceBetween": 15,
              },
              "768": {
                "slidesPerView": 1,
                "spaceBetween": 15,
              },
              "1024": {
                "slidesPerView": 2,
                "spaceBetween": 30,
              }
            }}
            pagination={{ 
              dynamicBullets: false,
              clickable: false 
            }}
            data-aos="fade-up" 
            data-aos-delay="100"
          >
            {testimonials.nodes.map((testimonial, index) => (
              <SwiperSlide key={index} className="my-2">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex gap-1 mb-2">
                      {testimonial.frontmatter.rating === '1' &&
                        <div>
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                        </div>
                      }
                      {testimonial.frontmatter.rating === '2' &&
                        <div>
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                        </div>
                      }
                      {testimonial.frontmatter.rating === '3' &&
                        <div>
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                        </div>
                      }
                      {testimonial.frontmatter.rating === '4' &&
                        <div>
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star-muted.svg" alt="Review rating" width={16} />
                        </div>
                      }
                      {testimonial.frontmatter.rating === '5' &&
                        <div>
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                          <StaticImage quality="50" src="../../images/svg/illustrations/star.svg" alt="Review rating" width={16} />
                        </div>
                      }
                    </div>
                    <div className="mb-auto">
                      <div className="card-text">
                        <MDXRenderer>
                          {testimonial.body}
                        </MDXRenderer>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer pt-0">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        {testimonial.frontmatter.from === 'google' &&
                          <StaticImage className="avatar avatar-xs avatar-circle" quality="50" src="../../images/brands/google-icon.svg" alt={t('testimonials.google')} />
                        }
                        {testimonial.frontmatter.from === 'facebook' &&
                          <StaticImage className="avatar avatar-xs avatar-circle" quality="50" src="../../images/brands/facebook-icon.svg" alt={t('testimonials.facebook')} />
                        }
                        {testimonial.frontmatter.from === 'trustpilot' &&
                          <StaticImage className="avatar avatar-xs avatar-circle" quality="50" src="../../images/brands/trustpilot-icon.svg" alt={t('testimonials.trustpilot')} />
                        }
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h5 className="card-title mb-0">{testimonial.frontmatter.name}</h5>
                        <p className="card-text small"><a href={testimonial.frontmatter.link} className="text-muted" target="_blank" rel="noreferrer noopener">{t(`testimonials.${testimonial.frontmatter.from}`)}</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))
          }
          </Swiper>
        </div>
      }
    </>
  );
};

export default TestimonialsSection