import * as React from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Typed from 'react-typed';
import ShareBlock from '../components/block/Share';

// Components
import Layout from '../components/layout/Layout';
import ExperiencesSection from '../components/section/Experiences';
import EducationsSection from '../components/section/Educations';
import CertificationsSection from '../components/section/Certifications';
import VolunteeringsSection from '../components/section/Volunteerings';
import SkillsSection from '../components/section/Skills';
import LanguagesSection from '../components/section/Languages';
import InterestsSection from '../components/section/Interests';
import TestimonialsSection from '../components/section/Testimonials';
import LastArticlesSection from '../components/section/LastArticles';
import LastRealisationsSection from '../components/section/LastRealisations';

const IndexPage = ({ data }) => {
  
  const {t} = useTranslation();

  return (
    <Layout 
      pageType="index" 
      pageTitle={t('meta.title')} 
      pageDescription={t('meta.description')} 
      pageKeywords={t('meta.keywords')} 
      headerClass="navbar-light navbar-show-hide" 
    >
      <div>
        <div className="container content-space-t-1 content-space-t-lg-1">
          <div className="border-top border-bottom py-5">
            <div className="row">
              <div className="col-lg mb-3 mb-lg-0">
                <h1 className="page-header-title h2">
                  <Typed
                    strings={t('title').split(',')}
                    typeSpeed={80}
                    backSpeed={50}
                      >
                  </Typed>
                </h1>
                <h2 className="h5 text-muted">{t('subtitle')}</h2>
                <ul className="list-inline list-separator">
                  {data.site.siteMetadata.addressGoogleDirection ? 
                    <li className="list-inline-item"><a className="link-sm link-secondary" href={data.site.siteMetadata.addressGoogleDirection}><i className="bi bi-geo-alt-fill text-primary me-1"></i> {data.site.siteMetadata.addressCity}, {data.site.siteMetadata.addressCountry}</a></li>
                  : null} 
                  {data.site.siteMetadata.telephone ? 
                    <li className="list-inline-item"><a className="link-sm link-secondary" href={"tel:" + data.site.siteMetadata.telephoneIndex}><i className="bi bi-telephone-inbound-fill text-primary me-1"></i> {data.site.siteMetadata.telephone}</a></li>
                  : null}
                  {data.site.siteMetadata.email ? 
                    <li className="list-inline-item"><a className="link-sm link-secondary" href={"mailto:" + data.site.siteMetadata.email}><i className="bi bi-envelope-fill text-primary me-1"></i> {data.site.siteMetadata.email}</a></li>
                  : null}
                </ul>
              </div>                  
              <div className="col-lg-auto align-self-lg-end text-lg-right">
                <div className="d-flex gap-2">
                  <ShareBlock 
                    data={data.site.siteMetadata.siteUrl} 
                    title={t('shareOn.title')}
                    description={t('shareOn.description')}
                    blockStyle="4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-b-2">
          <div className="row">
            <div className="col-lg-8">
              <div className="mb-8 pt-5">
                <h3>{t('resume.bio.title')}</h3>
                <p dangerouslySetInnerHTML={{__html: t('resume.bio.description')}}></p>
              </div>
              {data.experiences.nodes.length ?
                <div className="mb-8">
                  <div className="mb-4">
                    <h3>{t('resume.experience.title')}</h3>
                  </div>
                  <ExperiencesSection data={data} experiences={data.experiences} service="home"/>
                </div>
              : null} 
              {data.educations.nodes.length ?
                <div className="mb-8">
                  <div className="mb-4">
                    <h3>{t('resume.education.title')}</h3>
                  </div>
                  <EducationsSection data={data} educations={data.educations} service="home"/>
                </div>
              : null} 
              {data.certifications.nodes.length ?
                <div className="mb-8">
                  <div className="mb-4">
                    <h3>{t('resume.certifications.title')}</h3>
                  </div>
                  <CertificationsSection data={data} certifications={data.certifications} service="home"/>
                </div>
              : null} 
              {data.volunteerings.nodes.length ?
                <div className="mb-8">
                  <div className="mb-4">
                    <h3>{t('resume.volunteering.title')}</h3>
                  </div>
                  <VolunteeringsSection data={data} volunteerings={data.volunteerings} service="home"/>
                </div>
              : null} 
              {data.skills_technical.nodes.length || data.skills_professional.nodes.length || data.skills_personal.nodes.length ?
                <div className="mb-8">
                  <div className="mb-4">
                    <h3>{t('resume.skills.title')}</h3>
                  </div>
                  <SkillsSection data={data} skills_technical={data.skills_technical} skills_professional={data.skills_professional} skills_personal={data.skills_personal} service="home"/>
                </div>
              : null} 
              {data.languages.nodes.length ?
                <div className="mb-8">
                  <div className="mb-4">
                    <h3>{t('resume.languages.title')}</h3>
                  </div>
                  <LanguagesSection data={data} languages={data.languages} service="home"/>
                </div>
              : null} 
              {data.interests.nodes.length ?
                <div className="mb-8">
                  <div className="mb-4">
                    <h3>{t('resume.interests.title')}</h3>
                  </div>
                  <InterestsSection data={data} interests={data.interests} service="home"/>
                </div>
              : null} 
              <div className="mb-3">
                <h3>{t('resume.links.title')}</h3>
              </div>
              <div>
                <ul className="list-inline mb-0">
                  {data.site.siteMetadata.socialNetworks.linkedIn ? 
                    <li className="list-inline-item">
                      <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle" href={data.site.siteMetadata.socialNetworks.linkedIn} aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </li>
                  : null} 
                  {data.site.siteMetadata.socialNetworks.whatsApp ? 
                    <li className="list-inline-item">
                      <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle" href={data.site.siteMetadata.socialNetworks.whatsApp} aria-label="WhatsApp" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-whatsapp"></i>
                      </a>
                    </li>
                  : null} 
                  {data.site.siteMetadata.socialNetworks.gitHub ? 
                    <li className="list-inline-item">
                      <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle" href={data.site.siteMetadata.socialNetworks.gitHub} aria-label="GitHub" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-github"></i>
                      </a>
                    </li>
                  : null}
                  {data.site.siteMetadata.socialNetworks.facebook ? 
                    <li className="list-inline-item">
                      <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle" href={data.site.siteMetadata.socialNetworks.facebook} aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-facebook"></i>
                      </a>
                    </li>
                  : null} 
                  {data.site.siteMetadata.socialNetworks.messenger ? 
                    <li className="list-inline-item">
                      <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle" href={data.site.siteMetadata.socialNetworks.messenger} aria-label="Messenger" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-messenger"></i>
                      </a>
                    </li>
                  : null} 
                  {data.site.siteMetadata.socialNetworks.instagram ? 
                    <li className="list-inline-item">
                      <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle" href={data.site.siteMetadata.socialNetworks.instagram} aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-instagram"></i>
                      </a>
                    </li>
                  : null} 
                  {data.site.siteMetadata.socialNetworks.twitter ? 
                    <li className="list-inline-item">
                      <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle" href={data.site.siteMetadata.socialNetworks.twitter} aria-label="Twitter" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-twitter"></i>
                      </a>
                    </li>
                  : null} 
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sticky-top pt-5">
                <div className="card card-bordered">
                  <div className="card-body">
                    <div className="text-center mb-2">
                      <h3>{t('resume.hireMe.title')}</h3>
                      <ul className="list-unstyled justify-content-center border-bottom border-2 d-flex py-1 py-lg-2">
                        <li>
                          <a href="https://www.malt.fr/profile/thibaultcrouzet" target="_blank" rel="noopener noreferrer" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('resume.hireMe.malt')} aria-label={t('resume.hireMe.malt')}>
                            <StaticImage className="avatar avatar-circle m-3" imgClassName="avatar" objectFit="contain" quality="50" src="../images/brands/malt-icon.png" alt={t('resume.hireMe.malt')} />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.codeur.com/-thibaultcrouzet" target="_blank" rel="noopener noreferrer" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('resume.hireMe.codeur')} aria-label={t('resume.hireMe.codeur')}>
                            <StaticImage className="avatar avatar-circle m-3" imgClassName="avatar" objectFit="contain" quality="50" src="../images/brands/codeur-icon.png" alt={t('resume.hireMe.codeur')} />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.404works.com/fr/profile/thibaultcrouzet" target="_blank" rel="noopener noreferrer" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('resume.hireMe.404works')} aria-label={t('resume.hireMe.404works')}>
                            <StaticImage className="avatar avatar-circle m-3" imgClassName="avatar" objectFit="contain" quality="50" src="../images/brands/404works-icon.png" alt={t('resume.hireMe.404works')} />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="text-center mb-2">
                      <h3>{t('resume.findMe.title')}</h3>
                      <ul className="list-unstyled justify-content-center border-bottom border-2 d-flex py-1 py-lg-2">
                        {data.site.siteMetadata.socialNetworks.linkedIn ? 
                          <li className="list-inline-item">
                            <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle m-2" href={data.site.siteMetadata.socialNetworks.linkedIn} aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                              <i className="bi bi-linkedin"></i>
                            </a>
                          </li>
                        : null} 
                        {data.site.siteMetadata.socialNetworks.whatsApp ? 
                          <li className="list-inline-item">
                            <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle m-2" href={data.site.siteMetadata.socialNetworks.whatsApp} aria-label="WhatsApp" target="_blank" rel="noopener noreferrer">
                              <i className="bi bi-whatsapp"></i>
                            </a>
                          </li>
                        : null} 
                        {data.site.siteMetadata.socialNetworks.gitHub ? 
                          <li className="list-inline-item">
                            <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle m-2" href={data.site.siteMetadata.socialNetworks.gitHub} aria-label="GitHub" target="_blank" rel="noopener noreferrer">
                              <i className="bi bi-github"></i>
                            </a>
                          </li>
                        : null}
                        {data.site.siteMetadata.socialNetworks.facebook ? 
                          <li className="list-inline-item">
                            <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle m-2" href={data.site.siteMetadata.socialNetworks.facebook} aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                              <i className="bi bi-facebook"></i>
                            </a>
                          </li>
                        : null} 
                        {data.site.siteMetadata.socialNetworks.messenger ? 
                          <li className="list-inline-item">
                            <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle m-2" href={data.site.siteMetadata.socialNetworks.messenger} aria-label="Messenger" target="_blank" rel="noopener noreferrer">
                              <i className="bi bi-messenger"></i>
                            </a>
                          </li>
                        : null} 
                        {data.site.siteMetadata.socialNetworks.instagram ? 
                          <li className="list-inline-item">
                            <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle m-2" href={data.site.siteMetadata.socialNetworks.instagram} aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                              <i className="bi bi-instagram"></i>
                            </a>
                          </li>
                        : null} 
                        {data.site.siteMetadata.socialNetworks.twitter ? 
                          <li className="list-inline-item">
                            <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle m-2" href={data.site.siteMetadata.socialNetworks.twitter} aria-label="Twitter" target="_blank" rel="noopener noreferrer">
                              <i className="bi bi-twitter"></i>
                            </a>
                          </li>
                        : null} 
                      </ul>

                    </div>
                    <div className="d-grid">
                      <Link className="btn btn-primary btn-transition" to="/contact/" target="_self"><i className="bi-envelope-fill me-1"></i> {t('cta.contactMe')}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {data.testimonials.nodes.length ?
          <div className="bg-light">
            <div className="container content-space-2 content-space-lg-3">
              <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                <h2>{t('testimonials.title')}</h2>
              </div>
              <TestimonialsSection data={data} testimonials={data.testimonials} sectionStyle="1" service="home"/>
              <div className="text-center aos-init aos-animate" data-aos="fade-up">
                <div className="card card-info-link card-sm mb-5 mb-md-0">
                  <div className="card-body">
                    <Link className="card-link ms-2" to="/contact/">{t('cta.contactMe')} <span className="bi-chevron-right small ms-1"></span></Link>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        : null} 
        {data.lastArticles.nodes.length ?
          <div>
            <div className="container content-space-2 content-space-lg-3">
              <div className="w-md-75 text-center mx-md-auto mb-5">
                <h2>{t('lastArticles.title', { title: data.site.siteMetadata.title })}</h2>
                <p>{t('lastArticles.subtitle', { title: data.site.siteMetadata.title })}</p>
              </div>
              <LastArticlesSection data={data} articles={data.lastArticles} service="home"/>
              <div className="text-center aos-init aos-animate" data-aos="fade-up">
                <div className="card card-info-link card-sm mb-5 mb-md-0">
                  <div className="card-body">
                    <Link className="card-link ms-2" to="/articles/">{t('lastArticles.button')} <span className="bi-chevron-right small ms-1"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        : null} 
        {data.lastRealisations.nodes.length ?
          <div className="bg-light">
            <div className="container content-space-2 content-space-lg-3">
              <div className="w-md-75 text-center mx-md-auto mb-5">
                <h2>{t('lastRealisations.title', { title: data.site.siteMetadata.title })}</h2>
                <p>{t('lastRealisations.subtitle', { title: data.site.siteMetadata.title })}</p>
              </div>
              <LastRealisationsSection data={data} realisations={data.lastRealisations} service="home"/>
              <div className="text-center aos-init aos-animate" data-aos="fade-up">
                <div className="card card-info-link card-sm mb-5 mb-md-0">
                  <div className="card-body">
                    <Link className="card-link ms-2" to="/realisations/">{t('lastRealisations.button')} <span className="bi-chevron-right small ms-1"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        : null} 
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        site
        siteUrl
        title
        image {
          url
          secureUrl
          type
          width
          height
        }
        addressStreet
        addressPostalCode
        addressCity
        addressCountry
        addressFull
        addressGoogleDirection
        email
        telephone
        telephoneIndex
        socialNetworks {
          linkedIn
          whatsApp
          gitHub
          facebook
          messenger
          instagram
          twitter
        }
      }
    } 
    locales: allLocale(filter: {ns: {in: ["common", "header", "index", "resume", "article", "realisation", "categories", "tags", "share-on", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    experiences: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/resume/experiences/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date_start, order: DESC}
    )
    {
      nodes {
        frontmatter {
          title
          company
          city
          country
          website_url
          date_start(formatString: "MMM YYYY", locale: $language)
          date_end(formatString: "MMM YYYY", locale: $language)
          image {
            logo {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            alt
          }
          active
          language
        }
        id
        slug
        body
      }
    }
    educations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/resume/educations/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date_start, order: DESC}
    )
    {
      nodes {
        frontmatter {
          school
          city
          country
          degree
          field
          website_url
          date_start(formatString: "MMM YYYY", locale: $language)
          date_end(formatString: "MMM YYYY", locale: $language)
          image {
            logo {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            alt
          }
          active
          language
        }
        id
        slug
        body
      }
    }
    certifications: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/resume/certifications/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date_issue, order: DESC}
    )
    {
      nodes {
        frontmatter {
          title
          organization
          credential_id
          credential_url
          credential_language
          website_url
          date_issue(formatString: "MMM YYYY", locale: $language)
          date_expiry(formatString: "MMMM YYYY", locale: $language)
          image {
            logo {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            alt
          }
          active
          language
        }
        id
        slug
        body
      }
    }
    volunteerings: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/resume/volunteerings/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date_start, order: DESC}
    )
    {
      nodes {
        frontmatter {
          title
          organization
          city
          country
          website_url
          date_start(formatString: "MMM YYYY", locale: $language)
          date_end(formatString: "MMM YYYY", locale: $language)
          image {
            logo {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            alt
          }
          active
          language
        }
        id
        slug
        body
      }
    }
    skills_technical: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/resume/skills/" },
        frontmatter: { type: { eq: "technical" }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___progress, order: DESC}
    )
    {
      nodes {
        frontmatter {
          title
          type
          progress
          image {
            logo {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            alt
          }
          active
          language
        }
        id
        slug
      }
    }
    skills_professional: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/resume/skills/" },
        frontmatter: { type: { eq: "professional" }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___progress, order: DESC}
    )
    {
      nodes {
        frontmatter {
          title
          type
          progress
          image {
            logo {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            alt
          }
          active
          language
        }
        id
        slug
      }
    }
    skills_personal: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/resume/skills/" },
        frontmatter: { type: { eq: "personal" }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___progress, order: DESC}
    )
    {
      nodes {
        frontmatter {
          title
          type
          progress
          image {
            logo {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            alt
          }
          active
          language
        }
        id
        slug
      }
    }
    languages: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/resume/languages/" },
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___proficiency, order: DESC}
    )
    {
      nodes {
        frontmatter {
          proficiency
          active
          language
        }
        id
        slug
      }
    }
    interests: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/resume/interests/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___title, order: DESC}
    )
    {
      nodes {
        frontmatter {
          title
          active
          language
        }
        id
        slug
      }
    }
    testimonials: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/testimonials/" },
        frontmatter: { pages: {services: { in: ["index"] } }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
    )
    {
      nodes {
        frontmatter {
          name
          rating
          from
          link
          pages {
            services
            realisations
          }
          active
          language
          date(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        slug
        body
      }
    }
    lastArticles: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/articles/" },
        frontmatter: { home: { eq: true }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date_published, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          description
          author
          publisher
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date_published(formatString: "D MMMM YYYY", locale: $language)
          date_modified(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    lastRealisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { home: { eq: true }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          services
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
  }
`;
