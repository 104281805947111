import * as React from 'react';

// Components
import LanguageCard from '../../components/card/Language';

const LanguagesSection = ({ data, languages }) => {
  return (
    <>
      <ul className="list-unstyled list-py-1">
        {languages.nodes &&
          languages.nodes.map(language => (
            <LanguageCard key={language.id} data={language} cardStyle="1"/>
          ))
        }
      </ul>
    </>
  );
};

export default LanguagesSection