import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SkillCard = ({ data, cardStyle, cardClassName }) => {
  
  return (
    <>
    {cardStyle === "1" &&
      <div key={data.id} className="row mb-2">
        <div className="d-flex align-items-lg-center">
          <div className="flex-shrink-0">
            {data.frontmatter.image.logo && 
              <>
                {data.frontmatter.website_url ?
                  <a href={data.frontmatter.website_url} target="_blank" rel="noreferrer noopener">
                    <div className="avatar avatar-sm">
                      <GatsbyImage
                        image={getImage(data.frontmatter.image.logo)}
                        className="avatar-img"
                        alt={data.frontmatter.image.alt}
                      />
                    </div>
                  </a>
                :
                  <div className="avatar avatar-sm">
                    <GatsbyImage
                      image={getImage(data.frontmatter.image.logo)}
                      className="avatar-img"
                      alt={data.frontmatter.image.alt}
                    />
                  </div>
                }
              </>
            }
          </div>
          <div className="flex-grow-1 ms-4">
            <div className="row">
              <div className="col-3 mb-3 mb-lg-0">
                {data.frontmatter.title}
              </div>                  
              <div className="col-9 align-self-lg-end text-lg-right">
                <div className="progress" style={{height: '20px'}}>
                  <div className="progress-bar" role="progressbar" style={{width: Number(data.frontmatter.progress) + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{Number(data.frontmatter.progress)}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    {cardStyle === "2" &&
      <span key={data.id} className="badge bg-soft-dark text-dark py-2 px-3 my-1 me-1">{data.frontmatter.title}</span>
    }
    </>
  )
}

export default SkillCard