import * as React from 'react';

// Components
import InterestCard from '../../components/card/Interest';

const InterestsSection = ({ data, interests }) => {
  return (
    <>
      <div>
        {interests.nodes &&
          interests.nodes.map(interest => (
            <InterestCard key={interest.id} data={interest} cardStyle="1"/>
          ))
        }
      </div>
    </>
  );
};

export default InterestsSection